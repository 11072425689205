<template>
  <div class="profile">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h3 class="pt-5">
          Reset password request
        </h3>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <p>Please enter your email below and we will send you a link to reset your password.</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-4 medium-offset-3">
        <input
          v-model="email"
          class="sign-up-form-input"
          type="text"
          required
          placeholder="Email" />
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-6 medium-2 medium-offset-3">
        <button @click="requestReset" class="button expanded">Send</button>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="grid-x grid-padding-x pb-10">
      <div class="cell small-12 medium-6 medium-offset-3">
        <div
          @click="message = ''"
          class="message">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      message: '',
    };
  },
  methods: {
    requestReset() {
      const postData = {};
      postData.email = this.email;
      axios.post('/users/requestPasswordReset.json', postData)
        .then((response) => {
          this.message = response.data;
        });
    },
  },
};
</script>
